import React, { useRef, useState, useEffect } from "react"
// import styled from "styled-components"
import SEO from "../../components/seo.js"
import Layout from "../../components/layout.js"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

import { InPageNav } from "../../components/navbar.js"

import { HeroTitleH1 } from "../../components/typography.js"
import { Section } from "../../components/sections/sections.js"

// const Row = styled(Section)`
//   padding-top: 70px;
//   padding-bottom: 70px;
// `

// const RowContent = styled.div`
//   opacity: 0.1;
//   transition: opacity 0.3s ease-in;
//   width: 80%;
//   margin: 0 auto;
//   display: flex;
//   flex-flow: row nowrap;
//   justify-content: space-between;
// `
// const Title = styled(SectionTitle)`
//   font-size: 500;
// `
// const NumberTitle = styled(Title)`
//   font-size: 94px;
//   font-weight: 600;
// `

// const howClassWorksContent = [
//   {
//     title: "Get Onboarded",
//     description: "After you've signed up, you need to get to work",
//   },
//   {
//     title: "Set Your Test Date",
//     description: "Ladi blah black",
//   },
//   {
//     title: "Start With CARS I",
//     description: "Ladi blah black",
//   },
//   {
//     title: "Watch The Lecture",
//     description: "CARS is centered around ....",
//   },
//   {
//     title: "Pass the Lecture Quiz With Min. 80% Mark",
//     description: "Ladi blah black",
//   },
//   {
//     title: "Unlock The Next Lecture",
//     description: "Ladi blah black",
//   },
//   {
//     title: "Understands, Memorize, Repeat",
//     description:
//       "ndfjndsfjndsjfndsjfsj jjsfsdfds fsjfsfbdsff jdsbfsdjfdsjfsdj jbfdfhsdfs jfbdjfbdsjfs jbfsdfbsdfb",
//   },
// ]
const HowItWorks = () => {
  const [positions, setPositions] = useState([])
  const [currentHighlight, setHighlight] = useState(0)
  const itemRefs = useRef([])

  useEffect(() => {
    let positionsArray = itemRefs.current.map((el, index) => {
      if (typeof window !== "undefined") {
        return window.pageYOffset + el.getBoundingClientRect().top
      }
      return null
    })
    setPositions(positionsArray)
    return null
  }, [])

  // const handleRefs = (el, index) => {
  //   if (itemRefs.current.length < howClassWorksContent.length)
  //     itemRefs.current.push(el)
  // }

  const positionLocator = (
    currPos,
    scrollingDownBool,
    upperRange,
    lowerRange
  ) => {
    positions.forEach((row, index) => {
      while (
        positions[index] &&
        positions[index] > upperRange &&
        positions[index] < lowerRange
      ) {
        setHighlight(index)
        itemRefs.current[currentHighlight].style.opacity = "1"
        if (
          index - 1 > -1 &&
          itemRefs.current[index - 1].style.opacity === "1"
        ) {
          itemRefs.current[index - 1].style.opacity = "0.1"
        }
        if (
          index + 1 < positions.length &&
          itemRefs.current[index + 1].style.opacity === "1"
        ) {
          itemRefs.current[index + 1].style.opacity = "0.1"
        }
        break
      }
    })
  }
  useScrollPosition(({ prevPos, currPos }) => {
    if (typeof window !== "undefined") {
      let currX = Math.abs(currPos.y)
      let prevX = Math.abs(prevPos.y)
      let upperRange =
        window.innerHeight * 0.3 || document.documentElement.clientHeight * 0.3
      let lowerRange =
        window.innerHeight * 0.7 || document.documentElement.clientHeight * 0.7
      // let currentItemPos = itemRefs.current[
      //   currentHighlight
      // ].getBoundingClientRect().top
      positionLocator(
        currX,
        currX > prevX,
        currX + upperRange,
        currX + lowerRange
      )
    }
  })
  const RedirectToPage = dest => {
    // navigate(dest)
  }
  return (
    <Layout>
      <SEO title="How 99point9 GMAT Course Works" />
      <InPageNav
        pageTitle="How It Works"
        navItems={[
          { link: "/gmat", label: "Overview" },
          { link: "/gmat/how_it_works", label: "How It Works" },
          { link: "/gmat/schedule", label: "Class Schedule" },
        ]}
        clickCallback={RedirectToPage}
      />
      {/* <HeroTitleH1 align="center" float="center">HOW IT WORKS</HeroTitleH1> */}
      <Section>
        <HeroTitleH1 align="center" float="center">
          HOW IT WORKS
        </HeroTitleH1>
      </Section>
      <br />
      <br />
      <br />
      <br />
      <h4 style={{ textAlign: "center", width: "80%", margin: "0 auto" }}>
        Ironic, isn't it? The "how it works" page doesn't work{" "}
        <span role="img" aria-label="Crying face">
          🙄😜😂
        </span>{" "}
        <br />
        Come back in a few days, and we'll have this page up and running!
      </h4>
    </Layout>
  )
}

export default HowItWorks
